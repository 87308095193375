<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon >
            <menu />
          </el-icon>
          Users
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.username"
          placeholder="Username"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon >
            <search />
          </el-icon>
          Search
        </el-button>
        <el-button type="primary" @click="handleCreate" style="float:right;">
          <el-icon >
            <search />
          </el-icon>
          Create a User
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'LogId', order: 'descending' }"
      >
        <el-table-column
          prop="username"
          label="Username/Student No."
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Username"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="email"
          label="Email"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Username"
          width="360"
        ></el-table-column>
        <el-table-column
          prop="userRole"
          label="Role"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="createdTime"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="EnrolledTime"
          :formatter="$tableCellFormatter.formatDate"
          label="Enroll Time"
          width="120px"
        ></el-table-column>
        <el-table-column label="Operation" align="left">
          <template #default="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
              <el-icon >
                <edit />
              </el-icon>
              Edit
            </el-button>
            <el-button
              type="text"
              @click="resetPassword(scope.$index, scope.row)"
            >
              <el-icon >
                <key />
              </el-icon>
              Reset Password
            </el-button>
            <el-button
              type="text"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
            >
              <el-icon >
                <delete />
              </el-icon>
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>

      <el-dialog
        :title="dialogTitle"
        v-model="editVisible"
        :append-to-body="false"
        width="60%"
      >
        <el-form
          ref="mainForm"
          :model="form"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item prop="username" label="Username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item prop="email" label="Email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item prop="userRole" label="Role">
            <el-select v-model="form.userRole" placeholder="Please select...">
              <el-option label="Admin" value="Admin"></el-option>
              <el-option label="Student" value="Student"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Password">
            <el-input show-password="false" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="Note">
            <el-input type="textarea" rows="5" v-model="form.note"></el-input>
          </el-form-item>
          <el-form-item prop="status" label="Status">
            <el-select v-model="form.status" placeholder="Please select...">
              <el-option label="Normal" value="Normal"></el-option>
              <el-option label="Locked" value="Locked"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="editVisible = false">Cancel</el-button>
            <el-button type="primary" @click="saveEdit">Save</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getData, putData, postData, deleteData } from "../../service/api";
import { h } from "vue";

export default {
  name: "userList",
  data() {
    return {
      controllerUrl: "/user",
      query: {
        studentId: null,
        username: null,
        orderBy: null,
        orderDirection: null,
        pageIndex: 1,
        pageSize: 20,
      },
      tableData: [],
      dialogTitle: "",
      editVisible: false,
      newMode: false,
      itemTotal: 0,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      rules: {
        username: [
          {
            required: true,
            message: "Please input username",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please input email",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: this.newMode,
            message: "Please input password",
            trigger: "blur",
          },
        ],
        userRole: [
          {
            required: true,
            message: "Please select user role",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "Please select user status",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    let query = this.$route.query;
    console.log("UserList.created", query);
    if (query.username) {
      this.query.username = query.username;
    }

    this.loadData();
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        console.log(res);

        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection =
          sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to delete this item？", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          deleteData(this.controllerUrl, row.userId).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              //this.tableData.splice(index, 1);
              this.loadData();
            } else {
              this.$message.error(
                "Delete failed, error message: " + res.message
              );
            }
          });
        })
        .catch(() => {});
    },
    handleEdit(index, row) {
      console.log(row);
      this.dialogTitle = "Edit";
      this.idx = index;
      this.form = row;
      this.editVisible = true;
      this.newMode = false;
    },
    handleCreate() {
      this.form = { userRole: "Admin", status: "Normal" };
      this.dialogTitle = "New";
      this.editVisible = true;
      this.newMode = true;
    },
    resetPassword(index, row) {
      // twice to confirm to reset password
      this.$confirm("Are you sure to reset the user's password？", "Prompt", {
        type: "warning",
      })
        .then(() => {
          putData(
            `${this.controllerUrl}/reset_password_randomly`,
            row.userId
          ).then((res) => {
            if (res.result && res.code === "200") {
              this.$msgbox({
                title: "Reset password",
                message: h("p", null, [
                  h("div", null, "Password reset successfully"),
                  h("div", null, `Username: ${row.username}`),
                  h("div", null, [
                    h("span", null, `Password: `),
                    h(
                      "span",
                      {
                        val: `${res.result}`,
                        style:
                          "-webkit-user-select: all;-moz-user-select: all;-ms-user-select: all;user-select: all;cursor: pointer;",
                        onClick: this.copyPassword,
                      },
                      res.result
                    ),
                    h(
                      "a",
                      {
                        onClick: this.copyPassword,
                        val: `${res.result}`,
                        style: "margin-left:10px;cursor:pointer;color:#409EFF;",
                      },
                      "Copy"
                    ),
                  ]),
                ]),
                showCancelButton: true,
                confirmButtonText: "Send reset-password email",
                cancelButtonText: "Close",
                beforeClose: (action, instance, done) => {
                  if (action === "confirm") {
                    instance.confirmButtonLoading = true;
                    instance.confirmButtonText = "Sending...";
                    this.sendResetPasswordToUser(row.username, res.result)
                      .then(() => {
                        done();
                      })
                      .catch(() => {
                        instance.confirmButtonLoading = false;
                        instance.confirmButtonText =
                          "Send reset-password email";
                      });
                  } else {
                    done();
                  }
                },
              });
            } else {
              this.$message.error(
                "Reset password failed, error message: " + res.message
              );
            }
          });
        })
        .catch(() => {});
    },
    saveEdit() {
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          if (this.form.userId && this.form.userId.length === 22) {
            putData(this.controllerUrl, this.form.userId, this.form).then(
              (res) => {
                if (res.result && res.code === "200") {
                  this.editVisible = false;
                  this.$message.success("Update successfully");
                } else {
                  this.$message.error(
                    "Update failed, error message: " + res.message
                  );
                }
              }
            );
          } else {
            postData(this.controllerUrl, this.form).then((res) => {
              console.log(res);
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Create successfully");
                this.form = res.result;
                this.loadData();
              } else {
                this.$message.error(
                  "Create failed, error message: " + res.message
                );
              }
            });
          }
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
    copyPassword(evt) {
      let ctr = evt.target;
      let pwd = ctr.getAttribute("val");
      var _this = this;
      console.log(ctr, pwd);
      this.$copyText(pwd).then(
        function() {
          _this.$message.success("Copied");
        },
        function() {
          _this.$message.error("Can not copy");
        }
      );
    },
    sendResetPasswordToUser(username, password) {
      let data = {
        username: username,
        password: password,
      };
      console.log("sendResetPasswordToUser", data);
      const promise = new Promise((resolve, reject) => {
        postData(`${this.controllerUrl}/sendResetPasswordOut`, data)
          .then((res) => {
            console.log(res);
            if (res.result && res.code === "200") {
              resolve();
              this.$message.success("Reset-password-email sent successfully");
            } else {
              reject();
              this.$message.error(
                "Reset-password-email sent failed, error message: " +
                  res.message
              );
            }
          })
          .catch((error) => {
            reject();
            this.$message.error(
              "Reset-password-email sent failed, error message: " + error
            );
          });
      });
      return promise;
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
